<template>
    <b-card-actions
      action-collapse
      title="Kategori Harga Barang"
    >
      <b-row v-if="allowCreate()">
        <b-col md="2" sm="4" class="my-1">
          <b-button
            
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            @click.prevent="add"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            Add
          </b-button>
          
        </b-col>
      </b-row>
        <!-- Form Add || Edit Barang -->
        <modal-kategori-harga 
            @onSubmit="submit"
        />
          <!-- / -->
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group
            :label="$t('Sort')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            :label="$t('Filter')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
  
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(no)="row">
              <strong class="text-center">
                {{ (row.index + 1) }}
              </strong>
            </template>
            <template #cell(actions)="row">
              <b-button
                v-if="allowUpdate()"
              title="'Ubah'"
                size="sm"
                @click="edit(row.item)"
                class="mr-1"
                variant="outline-info"
              >
              <feather-icon icon="EditIcon"/>
              </b-button>
              <b-button
                v-if="allowDelete()"
              title="'Hapus'"
                size="sm"
                @click="remove(row.item)"
                class="mr-1"
                variant="outline-danger"
              >
              <feather-icon icon="TrashIcon"/>
              </b-button>
            </template>
  
            <template #row-details="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">
                    {{ key }}: {{ value }}
                  </li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </b-col>
        <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
          <pre>{{ infoModal.content }}</pre>
        </b-modal>
  
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </template>
  
  <script>
  import ModalKategoriHarga from './components/Modal.vue'
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import {
    BTable,VBTooltip,
    BAvatar,
    BBadge,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  } from "bootstrap-vue";
  
  export default {
    components: {
      BCardActions,VBTooltip,ToastificationContent,
      BModal,
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      ModalKategoriHarga
    },
    directives: {
      'b-tooltip': VBTooltip,
    },
    data() {
      return {
        showModal: false,
        id: null,
        perPage: 10,
        pageOptions: [10, 20, 50],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        infoModal: {
          id: "info-modal",
          title: "",
          content: "",
        },
        fields: [
          {
            key: "no",
            label: "No",
          },
          { key: "nama", label: "Nama Kategori", sortable: true },
          { key: "actions", label: "Aksi" },
        ],
        items: [],
        status: [
          {
            1: "Current",
            2: "Professional",
            3: "Rejected",
            4: "Resigned",
            5: "Applied",
          },
          {
            1: "light-primary",
            2: "light-success",
            3: "light-danger",
            4: "light-warning",
            5: "light-info",
          },
        ],
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      }
    },
    mounted() {
      this.$store.commit('kategori-harga/TOGGLE_SHOW_MODAL', false)
      this.getData()
    },
    methods: {
      add() {
        this.id = null
        this.$store.commit('kategori-harga/TOGGLE_SHOW_MODAL', true)
      },
      remove(item ){
        this.$swal({
          title: 'Anda yakin?',
          text: `Data Level "${item.nama_level}" ini akan dihapus`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            item.fungsi = 1 // soft delete
            this.$store.dispatch('level/save', [item])
            .then(() => {
              this.pesanBerhasilHapus()
              this.resetForm()
              this.getData()
            })
            .catch(e => {
              this.pesanGagalHapus()
              this.displayError(e)
              return false
            })
          }
        })
      },
      resetForm() {
        this.id = null
        this.nama_level = null
        this.showModalAdd = false
        this.showModalEdit = false
      },
      submit() {
        if(this.nama_level == null || this.nama_level == '') {
          this.pesanGagalSimpan()
          return false
        }
        let payload = {
          nama_level: this.nama_level
        }
  
        if(this.id) {
          payload.id = this.id
        }
  
        this.$store.dispatch('level/save', [payload])
        .then(() => {
          if(this.activeAction == 'tambah') {
            this.$store.commit('kategori-harga/SET_ACTIVE_ID', null)
          }
            this.nama_level = null
            this.$store.commit('kategori-harga/TOGGLE_SHOW_MODAL', false)
            this.getData()
            this.pesanBerhasilSimpan()
          })
        .catch(e => console.error(e))
      },
      edit(item) {
        let { id } = item
        this.$store.commit('kategori-harga/SET_ACTIVE_ID', id)
        this.$store.commit('kategori-harga/TOGGLE_SHOW_MODAL', true)
      },
      pesanBerhasilSimpan() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notifikasi',
            icon: 'CheckIcon',
            text: 'Berhasil menyimpan data',
            variant: 'success',
          },
        },
        {
          position:'bottom-right',
        })
      },
      pesanGagalSimpan() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notifikasi',
            icon: 'CheckIcon',
            text: 'Gagal menyimpan data, harap cek kembali data yang diisi',
            variant: 'warning',
          },
        },
        {
          position:'bottom-right',
        })
      },
      pesanBerhasilHapus() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notifikasi',
            icon: 'CheckIcon',
            text: 'Berhasil menghapus data',
            variant: 'success',
          },
        },
        {
          position:'bottom-right',
        })
      },
      pesanGagalHapus() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notifikasi',
            icon: 'CheckIcon',
            text: 'Gagal menghapus data, harap cek kembali data yang diisi',
            variant: 'warning',
          },
        },
        {
          position:'bottom-right',
        })
      },
      getData() {
        this.items = [
          {
            id: 1,
            nama: 'Grosir'
          },
          {
            id: 2,
            nama: 'Agen'
          },
          {
            id: 3,
            nama: 'Retail'
          },
        ]
        // this.$store.dispatch('level/getData', {order:"desc"})
        // .then(() => {
        //   this.items = this.$store.state.level.datas
        //   this.totalRows = this.items.length;
        // })
        // .catch(e => console.error(e))
      },
      resetInfoModal() {
        this.infoModal.title = "";
        this.infoModal.content = "";
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
  };
  </script>
  